import React, { useContext } from "react";
import ResponsiveMedia from "utils/responsive_media";
import TextButton from "components/button/textButton";
import { TextBold, TextNormal } from "components/style/textStyle";
import { DescriptionContainer } from "./style";
import { LanguageContext } from "templates/LocaleBase";

const Description = ({ paddingtop, padding, alignitems, textAlign, justifyContent, color, titleLineHeight, descLineHeight, whiteSpace, type, titleGap, descGap, title, desc, link, btnMarginTop }) => {
    const { isMobile } = ResponsiveMedia();
    const { isKo } = useContext(LanguageContext);

    let organize = null;
    function organizeType() {
        switch (type) {
            case "main":
                isMobile
                    ? (organize = {
                          title: { type: "24", padding: "0 0 16px" },
                          desc: { type: "14", padding: "0" },
                          link: { margin: "0 0 0" }
                      })
                    : (organize = {
                          title: { type: !isKo ? "40" : "42", padding: "0 0 50px" },
                          desc: { type: !isKo ? "18" : "20", padding: "0 0 30px 0" },
                          link: { margin: "0 0 0" }
                      });
                break;
            case "img_title":
                isMobile
                    ? (organize = {
                          title: { type: "30", padding: "0 0 16px" },
                          desc: { type: "16", padding: "0" },
                          link: { margin: "0 0 0" }
                      })
                    : (organize = {
                          title: { type: !isKo ? "44" : "42", padding: "0 0 16px" },
                          desc: { type: !isKo ? "18" : "20", padding: "0" },
                          link: { margin: "0 0 0" }
                      });
                break;
            case "normal":
                isMobile
                    ? (organize = {
                          title: { type: "26", padding: "0 0 22px" },
                          desc: { type: "16", padding: "10px 0 20px 0", letterSpacing: "-0.48px" },
                          link: { margin: "0 0 0" }
                      })
                    : (organize = {
                          title: { type: !isKo ? "40" : "36", padding: "0 0 30px" },
                          desc: { type: "18", padding: "10px 0 30px 0" },
                          link: { margin: "0 0 0" }
                      });
                break;

            default:
                organize = {
                    title: { type: "42", padding: "0 0 50px" },
                    desc: { type: "20", padding: "0 0 30px 0" },
                    link: { margin: "0 0 0" }
                };
                break;
        }
    }

    return (
        <DescriptionContainer paddingtop={paddingtop} padding={padding} alignitems={alignitems}>
            {organizeType()}
            {title && (
                <TextBold type={organize.title.type} color={color} textAlign={textAlign || "center"} whiteSpace={whiteSpace} padding={titleGap || organize.title.padding} lineHeight={titleLineHeight}>
                    {title}
                </TextBold>
            )}
            {desc && (
                <TextNormal
                    type={organize.desc.type}
                    color={color}
                    textAlign={textAlign || "center"}
                    whiteSpace={whiteSpace}
                    padding={descGap || organize.desc.padding}
                    lineHeight={descLineHeight}
                    letterSpacing={organize.desc.letterSpacing}
                >
                    {desc}
                </TextNormal>
            )}
            {link && <TextButton margin={organize.link.margin} justifycontent={justifyContent || "center"} data={link} btnMarginTop={btnMarginTop} />}
        </DescriptionContainer>
    );
};

export default Description;
