import React, { useContext, useState } from "react";
import ResponsiveMedia from "utils/responsive_media";
import { Container, Box } from "components/style/layoutStyle";
import { TextBold, TextNormal } from "components/style/textStyle";
import { CardTable, CardRow, CardCol, CardContainer, CardCompanyBox, CardCategoryText, CardCompanyText, CardTitleText, CardDetailButtonBox, MoreTextButtonBox, ButtonTitleText, ButtonIndexText, ButtonArrowWrapper } from "./style";
import Image from "components/image/image";
import { IC_ARROW_BR, IC_INTERVIEW_ARROW } from "../../constants/media/icon";
import { navigate } from "gatsby-link";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "templates/LocaleBase";

const InterviewCard = ({ data }) => {
    const { t, i18n } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    const { isKo } = useContext(LanguageContext);

    const [openIndex, setOpenIndex] = useState(data.items.length / 2);

    const handleInterviewIndex = () => {
        if (openIndex === data.items.length) setOpenIndex(data.items.length / 2);
        else setOpenIndex(openIndex * 2);
    };

    const moveToInterviewDetail = (id) => {
        if (i18n.language !== "ko") return;

        navigate("/references/interview", { state: { id: id } });
    };

    return (
        <Container margin={isMobile === "true" ? "0 0 60px" : "0 0 120px"} padding={isMobile === "true" ? "80px 0 40px" : "130px 0"} bgColor={"#f8f8f8"}>
            <Box flexDirection={"column"}>
                {isMobile !== "true" && (
                    <>
                        <TextBold margin={"0 0 24px"} type={isKo ? "36" : "40"}>
                            {t(data.title)}
                        </TextBold>
                        <TextNormal margin={"0 0 80px"} type={"20"}>
                            {t(data.subtitle)}
                        </TextNormal>
                    </>
                )}
                <CardTable>
                    <CardRow xs={isMobile === "true" ? "1" : "3"} gap={isMobile === "true" ? "40px 20px" : "80px 26px"}>
                        {data.items
                            .filter((item, index) => (isMobile ? index < openIndex : true))
                            .map((list, index) => {
                                return (
                                    <CardCol key={index} mobile={isMobile} onClick={() => moveToInterviewDetail(list.id)}>
                                        <CardContainer>
                                            <Image
                                                alt={list.company}
                                                filename={isMobile === "true" ? list.image_mobile : list.image}
                                                style={isMobile === "true" ? { width: "335px", height: "160px", borderRadius: "8px 8px 0 0" } : { width: "388px", height: "240px", borderRadius: "8px 8px 0 0" }}
                                            />
                                            <CardCompanyBox mobile={isMobile}>
                                                <CardCategoryText>{t(list.cat)}</CardCategoryText>
                                                <CardCompanyText>{t(list.company)}</CardCompanyText>
                                            </CardCompanyBox>
                                            <CardTitleText mobile={isMobile}>{t(list.title)}</CardTitleText>
                                            {i18n.language === "ko" && (
                                                <CardDetailButtonBox margin={isMobile === "true" ? "0 20px 0" : "0 40px 40px"}>
                                                    <span>{t(data.subText)}</span>
                                                    <Image filename={IC_ARROW_BR} alt="arrow_br" style={{ width: "48px", height: "48px" }} />
                                                </CardDetailButtonBox>
                                            )}
                                        </CardContainer>
                                    </CardCol>
                                );
                            })}
                        {isMobile && (
                            <MoreTextButtonBox onClick={() => handleInterviewIndex()}>
                                <ButtonTitleText>{t("REFERENCES_ITEM_BTN_TEXT")}</ButtonTitleText>
                                <ButtonIndexText color={"#713838"}>{openIndex}</ButtonIndexText>
                                <ButtonIndexText margin={"0 10px 0 0"}>/{data.items.length}</ButtonIndexText>
                                <ButtonArrowWrapper open={data.items.length === openIndex}>
                                    <Image filename={IC_INTERVIEW_ARROW} alt="more interview" />
                                </ButtonArrowWrapper>
                            </MoreTextButtonBox>
                        )}
                    </CardRow>
                </CardTable>
            </Box>
        </Container>
    );
};

export default InterviewCard;
