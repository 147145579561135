import React from "react";
import ResponsiveMedia from "utils/responsive_media";

import MoveToSignUp from "utils/move_to_sign_up";
import OpenLink from "utils/open_link";

import { Box, Container } from "components/style/layoutStyle";
import { TextBold, TextNormal } from "components/style/textStyle";
import { SquareButton, OutlineButton } from "components/button";
import { checkIsLogin } from "utils/common";
import moveToDashboard from "utils/move_to_dashboard";

import { useTranslation } from "react-i18next";

const PromotionBanner = ({
    bgColor,
    title = "PROMOTION_BANNER_TITLE",
    title_mobile = "PROMOTION_BANNER_TITLE_MOBILE",
    subtitle = "PROMOTION_BANNER_SUBTITLE",
    subtitle_mobile = "PROMOTION_BANNER_SUBTITLE_MOBILE",
    buttonCount = 2,
    leftTitle = "PROMOTION_LEFT_TITLE",
    leftButtonEvent,
    rightTitle = "PROMOTION_RIGHT_TITLE",
    rightButtonEvent
}) => {
    const { isMobile } = ResponsiveMedia();
    const isLogin = checkIsLogin();

    const { t, i18n } = useTranslation();

    const handleLeftButton = () => {
        if (leftButtonEvent !== undefined) {
            leftButtonEvent();
        } else {
            if (isLogin) return moveToDashboard();
            MoveToSignUp("회원가입", "무료 체험 시작");
        }
    };

    const handleRightButton = () => {
        if (rightButtonEvent !== undefined) {
            rightButtonEvent();
        } else {
            OpenLink("사용자문의", "도입문의", "채널톡");
        }
    };

    return (
        <Container bgColor={bgColor} padding={"0 20px"}>
            <Box padding={"65px 0"} bgColor={"#713838"} flexDirection={"column"} gap={"12px 0"} style={{ borderRadius: "10px" }}>
                <TextBold type={isMobile === "true" ? "24" : i18n.language === "ko" ? "30" : "32"} color={"#fff"} textAlign={"center"} whiteSpace={isMobile === "true" ? "pre-line" : "none"}>
                    {isLogin ? t(isMobile ? "LOGIN_BANNER_TITLE_MOBILE" : "LOGIN_BANNER_TITLE") : t(isMobile ? title_mobile : title)}
                </TextBold>
                <TextNormal type={isMobile === "true" ? "16" : "18"} color={"#fff"} textAlign={"center"} whiteSpace={isMobile === "true" ? "pre-line" : "none"}>
                    {isLogin ? t("LOGIN_BANNER_SUB_TITLE") : t(isMobile ? subtitle_mobile : subtitle)}
                </TextNormal>
                <Box padding={isMobile === "true" ? "18px 0 0" : "32px 0 0"} justifycontent={"center"} flexDirection={isMobile === "true" ? "column" : ""} gap={isMobile === "true" ? "12px 0" : "32px"}>
                    <SquareButton
                        fontSize={isMobile === "true" ? "16px" : "20px"}
                        height={isMobile === "true" ? "52px" : "56px"}
                        bgColor={"#fff"}
                        hBgColor={"#fff"}
                        aBgColor={"#fff"}
                        color={"#713838"}
                        hColor={"#713838"}
                        aColor={"#713838"}
                        text={isLogin ? t("LOGIN_TITLE") : t(leftTitle)}
                        onClickEvent={() => handleLeftButton()}
                    />
                    {buttonCount === 2 && (
                        <OutlineButton
                            fontSize={isMobile === "true" ? "16px" : "20px"}
                            height={isMobile === "true" ? "52px" : "56px"}
                            borderColor={"#fff"}
                            hBorderColor={"#fff"}
                            aBorderColor={"#fff"}
                            color={"#fff"}
                            hColor={"#fff"}
                            aColor={"#fff"}
                            bgColor={"#713838"}
                            hBgColor={"#713838"}
                            aBgColor={"#713838"}
                            text={isLogin ? t("CONTACT_US_TITLE") : t(rightTitle)}
                            onClickEvent={() => handleRightButton()}
                        />
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default PromotionBanner;
