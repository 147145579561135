import React from "react";
import { connect } from "react-redux";
import ResponsiveMedia from "utils/responsive_media";

import CustomLink from "components/link/customLink";
import Image from "components/image/image";
import { IC_TEXT_BUTTON_ARROW, IC_TEXT_BUTTON_DOWN } from "constants/media/icon";
import { showChannelTalk } from "hooks/useChannelIO";
import { ButtonBox, TextButtonIconWrapper } from "./style";
import PDF_KO from "constants/data/files/DONUE_certification_ko.pdf";
import PDF_EN from "constants/data/files/DONUE_certification_en.pdf";
import { useTranslation } from "react-i18next";

const TextButton = ({ serviceGuide, serviceIntroduce, margin, justifyContent, data, btnMarginTop }) => {
    const { isMobile } = ResponsiveMedia();
    const { t, i18n } = useTranslation();

    const type = data.type;
    const url = data.url;
    const title = data.title;

    const OpenWindow = (url) => {
        let URL = url;
        switch (URL) {
            case "채널톡":
                showChannelTalk();
                return;

            case "사용가이드": {
                let guideUrl = serviceGuide;

                URL = guideUrl;
                break;
            }

            case "서비스소개서":
                URL = serviceIntroduce;
                break;
            case "감사추적인증서": {
                if (i18n.language === "ko") URL = PDF_KO;
                else if (i18n.language === "en") URL = PDF_EN;
                else URL = PDF_KO;
            }
            default:
                break;
        }

        window.open(URL, "_blank");
    };

    const Link = () => {
        return (
            <ButtonBox mobile={isMobile} margin={margin} justifycontent={justifyContent} onClick={() => OpenWindow(url)} btnMarginTop={btnMarginTop}>
                <p>{t(title)}</p>
                <TextButtonIconWrapper>
                    <Image filename={IC_TEXT_BUTTON_ARROW} alt="arrow_btn" />
                </TextButtonIconWrapper>
            </ButtonBox>
        );
    };

    const Path = () => {
        const pathUrl = i18n.language === "ko" ? data.url : `/${i18n.language}${data.url}`;

        return (
            <CustomLink to={pathUrl} margin={margin}>
                <ButtonBox mobile={isMobile} justifycontent={justifyContent} btnMarginTop={btnMarginTop}>
                    <p>{t(title)}</p>
                    <TextButtonIconWrapper>
                        <Image filename={IC_TEXT_BUTTON_ARROW} alt="arrow_btn" />
                    </TextButtonIconWrapper>
                </ButtonBox>
            </CustomLink>
        );
    };

    const Download = () => {
        return (
            <ButtonBox mobile={isMobile} margin={margin} justifycontent={justifyContent} onClick={() => OpenWindow(url)} btnMarginTop={btnMarginTop}>
                <p>{t(title)}</p>
                <TextButtonIconWrapper>
                    <Image filename={IC_TEXT_BUTTON_DOWN} alt="download_btn" />
                </TextButtonIconWrapper>
            </ButtonBox>
        );
    };

    return (
        <>
            {type === "link" && <Link />}
            {type === "path" && <Path />}
            {type === "download" && <Download />}
        </>
    );
};

export default connect(
    (state) => ({
        serviceGuide: state.publicData.service_guide_link,
        serviceIntroduce: state.publicData.service_introduce_link
    }),
    null
)(TextButton);
