import React from "react";
import { connect } from "react-redux";
import ResponsiveMedia from "utils/responsive_media";

import Image from "components/image/image";
import { SERVICE_BANNER } from "constants/data/public";
import { Container, Box, ImgWrapper, TextWrapper, TitleText, TitleIconWrapper, SubtitleText } from "./style";
import { GaEventTracter } from "../../../utils/gaTracker";
import { useTranslation } from "react-i18next";

const serviceBannerLocale = ({ bgColor, serviceGuide, serviceIntroduce }) => {
    const { isMobile } = ResponsiveMedia();
    const { t, i18n } = useTranslation();

    const handleOpenUrl = (url) => {
        if (url === "사용가이드") {
            GaEventTracter("서비스개선", "도뉴 사용 가이드");
            let guideUrl = serviceGuide;
            url = guideUrl;
        }
        if (url === "서비스소개서") {
            GaEventTracter("서비스개선", "도뉴 서비스 소개서");
            url = serviceIntroduce;
        }
        window.open(url, "_blank");
    };

    return (
        <Container bgColor={bgColor} mobile={isMobile === "true"}>
            {SERVICE_BANNER.map((item, index) => {
                return (
                    <Box key={index} bgColor={bgColor === "#f8f8f8" && "#ffffff"} onClick={() => handleOpenUrl(item.link.url)}>
                        <ImgWrapper mobile={isMobile === "true"}>
                            <Image filename={item.img} alt={t(item.title)} />
                        </ImgWrapper>
                        <TextWrapper mobile={isMobile === "true"}>
                            <TitleText mobile={isMobile === "true"}>{t(item.title)}</TitleText>
                            <div>
                                <SubtitleText whiteSpace={isMobile === "true" ? "pre-line" : ""} type={item.link.type} mobile={isMobile === "true"}>
                                    <span>{t(item.subtitle)}</span>
                                    <TitleIconWrapper>
                                        <Image filename={item.icon} alt={t(item.title)} />
                                    </TitleIconWrapper>
                                </SubtitleText>
                            </div>
                        </TextWrapper>
                    </Box>
                );
            })}
        </Container>
    );
};

export default connect(
    (state) => ({
        serviceGuide: state.publicData.service_guide_link,
        serviceIntroduce: state.publicData.service_introduce_link
    }),
    null
)(serviceBannerLocale);
