import React from "react";
import ImageTitle from "../parts/imageTitle";
import CompanyLogos from "./companyLogos";
import InterviewCard from "./interviewCard";

const References = ({ title, company, interview }) => {
    return (
        <>
            <ImageTitle data={title} />
            <CompanyLogos data={company} />
            <InterviewCard data={interview} />
        </>
    );
};

export default References;
