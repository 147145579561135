import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import Image from "components/image/image";
import { BackgroundImageWrapper, Container } from "components/style/layoutStyle";
import Description from "../../parts/description/description";
import { useTranslation } from "react-i18next";

const ImageTitle = ({ data }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    const height = isMobile ? "320px" : "400px";

    return (
        <Container height={height}>
            <BackgroundImageWrapper maxWidth={"100%"}>
                <Image filename={isMobile === "true" ? data.img_mobile : data.img} alt={data.title} style={{ height: height }} />
            </BackgroundImageWrapper>
            <Description
                type={"img_title"}
                whiteSpace={isMobile === "true" ? "pre-line" : "none"}
                color={"#fff"}
                title={isMobile === "true" && data.title_mobile ? t(data.title_mobile) : t(data.title)}
                desc={isMobile === "true" && data.desc_mobile ? t(data.desc_mobile) : t(data.desc)}
            />
        </Container>
    );
};

export default ImageTitle;
