import * as React from "react";
import Seo from "components/seo";
import LayoutBanner from "../../templates/layoutBanner";
import References from "../../organisms/references";
import { REFERENCES_IMAGE_THUMB, REFERENCES_INTERVIEW, REFERENCES_TITLE } from "../../constants/data/references";
import { REFERENCES_META, REFERENCES_META_EN } from "../../constants/data/meta_data";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

const ReferencesPage = () => {
    const { i18n } = useTranslation();

    const seoData = useMemo(() => {
        if (i18n.language === "en") return REFERENCES_META_EN;

        return REFERENCES_META;
    }, [i18n.language]);

    return (
        <LayoutBanner>
            <Seo title={seoData.title} description={seoData.description} canonical={seoData.canonical} kakaoKeyword={seoData.kakao} />
            <References title={REFERENCES_TITLE} company={REFERENCES_IMAGE_THUMB} interview={REFERENCES_INTERVIEW} />
        </LayoutBanner>
    );
};

export default ReferencesPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
