import React, { useContext } from "react";
import ResponsiveMedia from "utils/responsive_media";
import { TextBold } from "components/style/textStyle";
import { Container, Box } from "components/style/layoutStyle";
import { LogoTable, LogoRow, LogoCol } from "./style";
import Image from "components/image/image";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "templates/LocaleBase";

const CompanyLogos = ({ data }) => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    const { isKo } = useContext(LanguageContext);

    return (
        <Container>
            <Box padding={isMobile === "true" ? "80px 20px 60px" : "130px 20px 120px"} gap={isMobile === "true" ? "40px" : "100px"} flexDirection={"column"}>
                <TextBold type={isMobile === "true" ? "26" : isKo ? "36" : "40"} textAlign={"center"}>
                    {isMobile === "true" ? t(data.title_mobile) : t(data.title)}
                </TextBold>
                <LogoTable>
                    <LogoRow style={{ width: "100%" }}>
                        {data.items.map((list, index) => {
                            return (
                                <LogoCol xs={isMobile === "true" ? "4" : "2"} key={index}>
                                    <Image imgStyle={{ objectFit: "contain" }} filename={list.image} alt={list.alt} />
                                </LogoCol>
                            );
                        })}
                    </LogoRow>
                </LogoTable>
            </Box>
        </Container>
    );
};

export default CompanyLogos;
