import styled from "styled-components";

export const Container = styled.div`
    background-color: ${(props) => props.bgColor};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    gap: 16px 20px;
    ${(props) =>
        props.mobile
            ? `
        padding: 40px 20px 60px;
        flex-direction: column;
        > div {
            width: 100%;
            min-width: 337px;
            height: 128px;
            padding: 26px 24px;
            gap: 26px;
            > img {
                width: 70px;
                height: 70px;
            }
        }
    `
            : `
        padding: 30px 0 120px;
        flex-direction: row;
        > div {
            width: 598px;
            height: 148px;
            padding: 26px 50px;
            gap: 36px;
            > img {
                width: 90px;
                height: 90px;
            }
        }
    `}
`;

export const Box = styled.div`
    border-radius: 8px;
    background-color: ${(props) => props.bgColor || "#f8f8f8"};
    display: flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;

    &:hover {
        box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.12);
    }
`;

export const ImgWrapper = styled.div`
    ${(props) =>
        props.mobile
            ? `
        width: 70px;
        height: 70px;
    `
            : `
        width: 90px;
        height: 90px;
    `}
    object-fit: contain;
`;

export const TextWrapper = styled.div`
    ${(props) =>
        props.mobile
            ? `
        > div {
            &: nth-child(1) {
                font-size: 13px;
                letter-spacing: -0.13px;
            }
            &: nth-child(2) {
                font-size: 18px;
                line-height: 1.57;
                letter-spacing: -0.42px;
            }
        }
        
    `
            : `
        > div {
            &: nth-child(1) {
                font-size: 18px;
                line-height: 1.33;
                letter-spacing: -0.72px;
            }
            &: nth-child(2) {
                font-size: 26px;
                line-height: 1.38;
                letter-spacing: -0.48px;
            }
        }
        
    `}
`;

export const TitleText = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.18px;
    text-align: left;
    color: #713838;

    margin-top: 13px;
    margin-bottom: 4px;
`;

export const TitleIconWrapper = styled.div``;

export const SubtitleText = styled.div`
    /* font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    color: #1e1e1e;
    white-space: ${(props) => props.whiteSpace}; */

    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.18px;
    text-align: left;
    color: #713838;

    display: flex;
    align-items: center;

    ${(props) =>
        props.type === "download"
            ? `
        margin: 0 0 7px;

        > div {
            ${
                props.mobile
                    ? `
                width: 24px;    
                height: 24px;
            `
                    : `
                width: 32px;
                height: 32px;
            `
            }
            margin: 0 0 2px 4px;
        }
    `
            : `
        margin: 0 0 8px;
        > div {
            ${
                props.mobile
                    ? `
                width: 20px;    
                height: 20px;
            `
                    : `
                width: 32px;
                height: 32px;
            `
            }
            margin: 2px 0 0px 2px;
        }
    `};
`;
