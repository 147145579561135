import { showChannelTalk } from "hooks/useChannelIO";
import { GaEventTracter } from "./gaTracker"

export default function OpenLink(gategory, label, url) {
    if(gategory !== '' && label !== '') {
        GaEventTracter(gategory, label);
    }

    if(url === '채널톡') {
        showChannelTalk();
    } else {
        window.open(url, '_blank') 
    };
}