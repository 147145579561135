import styled from "styled-components";

export const DescriptionContainer = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    padding: ${(props) => props.padding};
    padding-top: ${(props) => props.paddingtop} !important;
    align-items: ${(props) => props.alignitems || `center`};
    justify-content: ${(props) => props.justifycontent || `center`};
    flex-direction: column;
    flex: 1.5;
`;

// Desc Tab

export const DescBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.bgColor};
    padding: ${(props) => props.padding};
`;

export const DescWrapper = styled.div`
    width: 100%;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
